<template>
  <div>
    <suy-table v-model:columns="columns" :reload="loadData">
      <template #search>
        <a-form layout="inline" :model="state">
          <a-form-item label="客户电话">
            <a-input v-model:value="phone" placeholder="请输入客户电话" />
          </a-form-item>
          <!-- <a-form-item label="始发地">
            <a-cascader :options="$store.state.app.cityDict" @change="destination" :changeOnSelect="true" placeholder="请选择起点城市" />
          </a-form-item>
          <a-form-item label="目的地">
            <a-cascader :options="$store.state.app.cityDict" @change="destination" :changeOnSelect="true" placeholder="请选择终点城市" />
          </a-form-item> -->
          <a-form-item>
            <a-button type="primary" @click="searchBtn" :loading="loading">搜索 </a-button>
          </a-form-item>
        </a-form>
      </template>
      <!-- 表格主体 -->
      <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" :bordered="false" :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #status="{ record }">
          <div>
            <span>
              {{record.status }}
            </span>
          </div>
        </template>
        <template #operation="{ record }">
          <div>
            <span>
              <a v-if="record.empName===''" @click="onReceiving(record)">接单</a>
            </span>
            <a-divider type="vertical" />
            <span>
              <a @click="onDetail(record)">修改</a>
            </span>
          </div>
        </template>
      </a-table>
    </suy-table>
    <a-modal v-model:visible="visible" title="修改联系状态" @ok="onEdit" @cancel="close" destroyOnClose>
      <a-form :model="dataForm">
        <a-form-item label="是否联系">
          <a-radio-group :checked="checked" :options="completeStatus" :v-model:value="dataForm.status.value" @change="changeStatus" />
        </a-form-item>
        <a-form-item label="备注信息">
          <a-textarea v-model:value="dataForm.remark" style="width:200px" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import { onMounted, reactive, toRefs } from 'vue'
import SuyTable from '@/components/SuyTable'
import { PrefixZero } from '@/utils/util'
import { page, editData, editReceiving } from '@/api/inquiry/clueList'
export default {
  components: {
    SuyTable
  },
  setup () {
    const state = reactive({
      visible: false,
      loading: false,
      checked: true,
      phone: '',
      dataForm: {
        status: {}
      },
      listData: [],
      columns: [
        {
          title: '编号',
          dataIndex: 'number',
          slots: {
            customRender: 'number'
          }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
        },
        {
          title: '电话号',
          dataIndex: 'phone',
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
        },
        {
          title: '接单员',
          dataIndex: 'empName',
        },
        {
          title: '是否联系',
          dataIndex: 'status.label'

        },

        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      completeStatus: [
        {
          label: '是',
          value: 1
        }
      ],
      pagination: {
        current: 1,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
    })

    const loadData = () => {
      state.loading = true
      page({
        phone: state.phone,
        current: state.pagination.current,
        size: state.pagination.size
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.listData.forEach((item, index) => {
            item.number = PrefixZero(index + 1, 3)
          })
          state.pagination.total = res.data.total
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.loading = false
        }
      }).catch(err => console.log(err))
    }
    const searchBtn = () => {
      loadData()
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 修改
    const onEdit = () => {
      editData(state.dataForm).then(res => {
        if (res.code === 10000) {
          message.info(res.msg)
          close()
        }
      }).catch(err => console.log(err))
    }
    // 打开详情
    const onDetail = (record) => {
      state.visible = true

      if (record.status.value === 1) {
        state.checked = true
      }
      state.dataForm = record

    }
    //接单
    const onReceiving = (record) => {
      editReceiving(record).then(res => {
        if (res.code === 10000) {
          message.info(res.msg)
          loadData()
        }
      }).catch(err => console.log(err))
    }
    // 取消
    const close = () => {
      state.visible = false
      loadData()
    }
    // 选择是
    const changeStatus = (e) => {
      console.log('e', e);
      state.dataForm.status = { value: 1, label: '是' }
    }
    return {
      ...toRefs(state),
      handleTableChange,
      searchBtn,
      loadData,
      onReceiving,
      onEdit,
      onDetail,
      close,
      changeStatus
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
