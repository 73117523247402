import {
  postBodyRequest
} from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/trans/order/clue/page', params)
}


export function editData (params) {
  return postBodyRequest('/trans/order/clue/edit', params)
}

export function editReceiving (params) {
  return postBodyRequest('/trans/order/clue/receiving', params)
}
